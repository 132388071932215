import {ErrorHandler, Inject, Injectable, Injector, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogComponent} from '@common/error-dialog/error-dialog.component';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandlerService} from '@common/error-handler.service';
import * as moment from 'moment';
import {SlackService} from './slack.service';
import {UserService} from '@common/user.service';
import {User} from '@api';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NotificationService} from '@common/notification.service';
import {GlobalLogListener} from './global-log-listener';
import {NGXLogInterface} from 'ngx-logger';
import {environment} from '../environments/environment';
import packageInfo from '../../package.json';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  private readonly currentVersion: string = packageInfo.version;

  private dialogRef;

  constructor(private injector: Injector,
              @Inject('origin') private origin: string,
              @Inject('isBeta') private isBeta: boolean) {
  }

  async handleError(error: any) {
    try {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (error.message && chunkFailedMessage.test(error.message)) {
        window.location.reload();
        return;
      }

      let wasErrorHandled = false;

      if (error.status === 0 || `${error.message}`.includes('0 Unknown Error')) {
        const notificationService = this.injector.get<NotificationService>(NotificationService);
        notificationService.displayNotification('Es sind Probleme mit Ihrer Internetverbindung aufgetreten.', 5000, true);
        wasErrorHandled = true;
      } else if (error instanceof HttpErrorResponse) {
        const errorHandler = this.injector.get<ErrorHandlerService>(ErrorHandlerService);
        wasErrorHandled = errorHandler.handleBackendErrorResponse(error);
      }

      if (!wasErrorHandled) {
        this.sendErrorInSlack(error);

        if (!this.dialogRef || !this.dialogRef.componentInstance) {
          const dialogManager = this.injector.get<MatDialog>(MatDialog);
          const ngZone = this.injector.get<NgZone>(NgZone);

          ngZone.run(() => {
            this.dialogRef = dialogManager.open(ErrorDialogComponent, {
              width: '500px'
            });
          });
        }
      }
    } catch (e) {
      console.error(e);
    }

    if (this.isBeta) {
      // throw the error in development environments so that the developer can analyze them in the console
      throw error;
    }
  }

  sendErrorInSlack(error: any): void {
    const userService: UserService = this.injector.get(UserService);
    const deviceDetectorService: DeviceDetectorService = this.injector.get(DeviceDetectorService);

    userService.currentUser.subscribe((user: User) => {
      let body = `\`${error.message || error.toString()}\``;

      if (error instanceof HttpErrorResponse) {
        if (error.error.msg) {
          body += `\n\`${error.error.msg}\``;
        }

        if (error.error.clsName) {
          body += `\nClass Name:\t\t${error.error.clsName}`;
        }

        const reqId = error.headers.getAll('X-Request-Id');
        body += `\nX-Request-Id:\t\t${reqId}`;
      }

      body += `\nAktuelle Seite:\t${window.location}`;
      body += `\nTimestamp:\t\t${moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}`;
      if (!!user) {
        body += `\nUser-ID:\t\t${user.id}`;
      }
      body += `\nSystem:\t\t\t${deviceDetectorService.os} ${deviceDetectorService.os_version}`;
      body += ` (${deviceDetectorService.isDesktop() ? 'Desktop' : deviceDetectorService.isTablet() ? 'Tablet' : 'Mobile'})`;
      body += `\nBrowser:\t\t${deviceDetectorService.browser} ${deviceDetectorService.browser_version}`;
      body += `\nFrontend Version:\t${this.currentVersion}`;

      if (GlobalLogListener.latestLogs.length) {
        body += `\n\nFrontend Logs:`;
        GlobalLogListener.latestLogs.forEach((log: NGXLogInterface) => body += `\n${log.timestamp} [${log.fileName}:${log.lineNumber}] ${log.message}`);
      }

      if (!this.origin.includes('localhost') && environment.production) {
        const slackService = this.injector.get<SlackService>(SlackService);
        slackService.sendSlackSupportMessage(body);
      } else {
        console.error(body);
      }
    });
  }

}
